@import '../../index.scss';

.app_blog {
    width: 100%;
    height: 100%;
    background: $white;
    
    // @media screen and (max-width: 850px) {
    //   height: 280vh;
    // }
    
    // @media screen and (max-width: 750px) {
    //   height: 250vh;
    // }
    
}

.app_blog_container { 
  width: 100%;
  margin-top: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  position: relative;

  .blog_item {
    width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 5px;
    font-family: $font-primary;
    text-align: center;
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 30px;
    background: $bright-white;
    
    h3 {
        font-weight: normal;
        letter-spacing: 1px;
        font-weight: normal;
        font-size: 22px;
        color: $black;
        margin-top: 10px;
    }

    p {
        line-height: 20px;
        margin-top: 20px;
        color: $black;
        letter-spacing: 1px;
        text-transform: capitalize;
    }

    .blog_icon {
      width: auto;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;

      img {
        width: 100px;
        height: 80px;
        border-radius: 10px;
      }
    }
    .schedule_button {
      width: 200px;
      height: 40px;
      background: $aqua;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 5px;
      margin-top: 20px;

      a {
        text-decoration: none;
        color: black;
        font-family: $font-primary;
        font-weight: 400;
      }
      
      a:hover {
        transition: 0.3s;
        color: $white;
      }

      svg {
        margin-left: 10px;
        color: black;
        vertical-align: middle;
        margin-bottom: 5px;

        :hover {
          transition: 0.3s; 
          color: $white;
        }
      }
    }

    @media screen and (max-width: 850px) {
      margin-right: 0;
    }

    @media screen and (max-width: 750px) {
      width: 350px;
      margin-right: 0;
    }
    
    @media screen and (max-width: 650px) {
      margin-right: 0;
    }
    
    @media screen and (max-width: 550px) {
      margin-left: 0;
    }

    @media screen and (max-width: 450px) {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 1400px) {
    margin-top: 200px;
  }

  @media screen and (max-width: 850px) {
    margin-top: 150px;
    margin-left: 0;
  }

  @media screen and (max-width: 550px) {
    margin-right: 0;
  }
}

.app_blog_banner {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  font-family: $font-primary;
  padding-top: 300px;

  h1 {
      color: $black;
      font-weight: normal;
      font-size: 42px;
      line-height: 60px;
      margin-top: 100px;
  }

  .blog_tag {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 40px;
      background: $blue;
      border-radius: 5px;
      position: absolute;
      margin-bottom: 250px;

      h3 {
          font-weight: 400;
          color: $white;
      }

  } 
  
  .blog_title {
      text-align: center;

      @media screen and (max-width: 850px) {
        margin-top: 200px;
        width: 600px;
      }
      
      @media screen and (max-width: 650px) {
        margin-top: 100px;
        width: 300px;
      }
  }

  img {
    width: 200px;
    height: 200px;
    margin-top: 50px;
  }

@media screen and (max-width: 650px) {
  padding-top: 100px;
  justify-content: space-between;
}

}

.app_myPosts_section{
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: $font-primary;
  margin-top: 50px;
}

.myPosts_header {
  display: flex;
  justify-content: center;

  h1 {
    letter-spacing: 2px;
    font-weight: lighter;
    font-size: 52px;
  }
}

.app_blog_personal {
  font-family: $font-primary;
  padding: 20px;
  width: auto;

  .post-item {
    width: auto;
    border-radius: 5px;
    background-color: $bright-white;
    padding: 10px;

    .post-title {
      font-weight: bold;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .post-date {
      font-style: italic;
      font-size: 12px;
      padding-bottom: 5px;
    }

    .post-content {
      padding-top: 50px;
    }
  }
  
  .post-icon {
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }
}

