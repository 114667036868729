@import '../../index.scss';

*{
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

.app_navbar {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: fixed;
  background: $black;
  justify-content: space-evenly;
  z-index: 4;

  .navbar_img {
    margin-right: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
    p {
      position: absolute;
      padding-left: 100px;
      font-family: $font-primary;
      font-size: 16px;
      color: $bright-white;
      margin-top: 10px;
      font-weight: 500;

      @media screen and (max-width: 1300px) {
        font-size: 18px;
      }
    }

    img {
      width: 100px;
    }
  }

  .navbar_items {
    display: flex;
    list-style: none;
    align-items: center;
    margin-left: 30px;
    
    li {
      display: flex;
      padding: 0px 30px;

      @media screen and (max-width: 2000px) {
        padding: 0px 20px;
      }
    }

    a { 
      text-decoration: none;
      font-family: $font-primary;
      font-size: 20px;
      color: $bright-white;

      @media screen and (min-width: 2000px) {
        font-size: 18px;
      }
      
      @media screen and (max-width: 2000px) {
        font-size: 16px;
      }
      
      @media screen and (max-width: 1300px) {
        font-size: 10px;
      }
    }

    a:hover {
      color: grey;
      transition: 0.5s;
    }

    @media screen and (max-width: 2000px) {
      margin-left: 150px;
    }
    
    @media screen and (max-width: 1500px) {
      margin-left: 60px;
    }
  }

  hr {
    height: 30px;
    margin-top: 35px;
    margin-right: 15px;
    color: black;

    @media screen and (max-width: 2500px) {
      margin-right: 20px;
    }
  }

  .navbar_socials {
    display: flex;
    align-items: center;
    justify-content: space-around;
    
    a {
      color: $bright-white;
      padding-right: 50px;
      padding-left: 10px;

      @media screen and (max-width: 2000px) {
        padding-right: 25px;
        padding-left: 5px;
      }
    }

    a:hover {
      color: grey;
      transition: 0.5s;
    }

    @media screen and (min-width: 2500px) {
      margin-right: 500px;
    }
    
    @media screen and (max-width: 2500px) {
      margin-right: 300px;
    }
    
  }

  @media screen and (max-width: 1200px) {
    display: none;
  }
}

/*************Mobile Device Screens**************/

.app_menu {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background: $black;
  width: 100%;

  .menu_img {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    // .icon-container {
    //   width: 120px;
    // }
  
    img {
      width: 100px;
      height: 80px;
    }
  }

  hr {
    display: none;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: center;
  }

  a { 
    text-decoration: none;
    color: $white;
    font-family: $font-primary;
    font-weight: 100;
  }

  .overlay_open {
    width: 70px;
    height: 70px;
    color: $white !important;
    margin-left: 175px;

    @media screen and (min-width: 300px) {
      margin-left: 200px;
    }

    @media screen and (min-width: 500px) {
      margin-left: 300px;
    }

    @media screen and (min-width: 600px) {
      margin-left: 400px;
    }

    @media screen and (min-width: 700px) {
      margin-left: 500px;
    }

    @media screen and (min-width: 800px) {
      margin-left: 600px;
    }

    @media screen and (min-width: 900px) {
      margin-left: 700px;
    }

    @media screen and (min-width: 1000px) {
      margin-left: 800px;
    }

    @media screen and (min-width: 1100px) {
      margin-left: 900px;
    }

  }

  .menu_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;

    .menu_items {
      padding-right: 0;

      li {
        padding: 15px;
        font-size: 24px;
      }
    }

  }  

  .menu_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: $black;
    flex-direction: column;
    z-index: 5;

    svg {
      color: $white !important;
    }

    .overlay_close {
      margin-right: 100px;
      margin-top: 50px;
      width: 70px;
      height: 70px;
    }
  }

  .menu_socials {
    display: flex;
    z-index: 6;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      padding: 10px;
      margin-top: 30px;
      
    }

    p {
      margin-top: 100px;
      font-family: $font-primary;
      font-weight: 100;
      color: $aqua;
      font-size: 24px;
      letter-spacing: 2px;
    }

  }

  .overlay_close {
    top: 0;

    svg {
      color: black;
    }
  }

  .menu_overlay, .overlay_close {
    font-size: 27px;
    cursor: pointer;
    position: absolute;
    right: -50px;
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }
  
}