@import '../../index.scss';

.app_privacy {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 100px 15px 0;
  font-family: $font-primary;
}

.privacy-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;

  h1 {
    font-size: 48px;
  }
}

.privacy-body {
  width: auto;
  padding-bottom: 10px;

  h3 {
    padding-bottom: 5px;
  }
}