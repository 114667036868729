@import '../../index.scss';

*{
  margin: 0;
  box-sizing: border-box;
}
.app_contact {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: $black;
  font-family: $font-primary;
}

.app_contact_nav {
  width: 50%;
  display: flex;
  flex-direction: column;

  .logo_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 100px;
    margin-left: 30px;

    h3 {
      font-weight: normal;
      color: $bright-white;
      letter-spacing: 1px;

      @media screen and (max-width: 850px) {
        font-size: 14px;
      }
    }
    
    img {
      width: 100px;
      height: 80px;
    }

    @media screen and (max-width: 850px) {
      width: 100%;
    }
  }

  .items_container {
    width: 400px;
    height: 200px;
    display: flex;
    justify-content: flex-start;
    margin-left: 50px;

    .nav_items {
      list-style: none;
      padding: 10px;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;

      a {
        text-decoration: none;
        color: $bright-white;
      }

      a:hover {
        color: $grey;
        transition: 0.3s;
      }
    }

    @media screen and (max-width: 650px) {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 650px) {
    font-size: 14px;
    width: 38%;
  }
}

.app_contact_container {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 110px;

  .contact_items {
    list-style: none;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    li {
      padding-bottom: 7px;
    }

    a {
      text-decoration: none;
      color: $bright-white;
    }

    a:hover {
      color: $grey;
      transition: 0.3s;
    }

    li, svg {
      vertical-align: text-top;
      display: inline-block;
      color: $bright-white;
    }

    svg:hover {
      color: $grey;
      transition: 0.3s;
    }

    .design_tag a {
      color: $blue;
    }

    .design_tag a:hover {
        color: $grey;
        transition: 0.3s;
    }

    @media screen and (max-width: 650px) {
      width: 230px;
      margin-left: 0;
    }
  }

  @media screen and (max-width: 850px) {
    font-size: 14px;

  }
} 

.app_contact_banner {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 200px;
  margin-top: 10px;
  justify-content: space-between;
  padding: 20px;

  h1 {
    font-size: 72px;
    color: $aqua;

    a {
      text-decoration: none;
      color: $aqua;
    }

    a:hover {
      color: $grey;
    }

    @media screen and (max-width: 650px) {
      font-size: 48px;
    }
  }

  svg {
    width: 100px;
    height: 75px;
    color: $blue;

    @media screen and (max-width: 650px) {
      margin-left: 140px;
    }
  }

  svg:hover {
    color: $grey;
    transition: 0.3s;
  }

  @media screen and (max-width: 650px) {
    justify-content: flex-start;
    margin-top: 0;
  }
}
