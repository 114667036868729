///Earth Tone Theme///
$grey: #525252;
$peach: #D7C0AE;
// $blue: #9BABB8;
$cranberry: #451a03;
$olive: #4b5320;
$stone: #a8a29e;
$cream: #EEE3CB;
$brown: #967E76;


///Ocean Color Theme///
$red: #C4421A;
$orange: #F66D38;
$beige: #FFF0EA;
$teal: #12908E;
$green: #16594A;
$aqua: #5CDEE5;
$grad1: linear-gradient(to left bottom, #f46840, #ba4a3a, #81312e, #491c1e, #190304);
$grad2: linear-gradient(to left bottom, #b3310b, #c03e0f, #cd4a13, #d95718, #e6631c);
$grad3: radial-gradient(circle, #e5f0f8, #b9d7e4, #89bfcc, #56a8b0, #12908e);
$gold: #D4A26B;
$off-white: #E8E6D5;
$dark-grey: #4D4D4D;

// Winter Color Theme //
$white: #E9F8F9;
$bright-white: #FFF;
$blue: #537FE7;
$aqua: #C0EEF2;
$black: #181823;

///Fonts///
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;600;700&family=Roboto:wght@100;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;600;700&family=Poppins:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');

@font-face {
    font-family: Aeonik;
    src: url(../public/fonts/AeonikTRIAL-Regular.otf);
    font-weight: normal;
}

@font-face {
    font-family: Aeonik;
    src: url(../public/fonts/AeonikTRIAL-Bold.otf);
    font-weight: bold;
}

@font-face {
    font-family: Aeonik;
    src: url(../public/fonts/AeonikTRIAL-Light.otf);
    font-weight: lighter;
}

.Aeonik {
    font-family: Aeonik;
}

// .Aeonik {
//     font-family: AeonikBold;
// }

// .Aeonik {
//     font-family: AeonikLight;
// }


$font-cursive: Italianno, sans-serif;
$font-alt: Poppins, sans-serif;
$font-secondary: Montserrat, sans-serif;
$font-primary: Aeonik, sans-serif;


* {
  box-sizing: border-box;
  margin: 0;
  scroll-behavior: smooth;
}
// .app_wrapper {
//   position: relative;
//   background-position: center;
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-image: url('./assets/bgart.png');
//   background: $white;
//   z-index: 0;
// }


