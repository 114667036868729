@import '../../index.scss';

.app_about {
  width: 100%;
  height: 100%;
  background: $white;
  position: relative;
  padding-top: 50px;
}

.app_about_header {
  width: 100%;
  height: 100%;
  background: $white;
  display: flex;
  justify-content: space-evenly;

  .app_accolades {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-family: $font-primary;
    color: black;
    width: 130px;
    height: 75px;
    padding: 10px;
    background: $white;
    border-radius: 5px;
    line-height: 25px;

    h3 {
      color: $blue;

      @media screen and (max-width: 650px) {
        font-size: 18px;
      }
    }
    
    p {
      @media screen and (max-width: 650px) {
        font-size: 14px;
      }
    }

    @media screen and (max-width: 650px) {
      justify-content: flex-start;
    }
  }

  @media screen and (max-width: 650px) {
    flex-wrap: wrap;
    padding-left: 20px;
  }
}


.app_about_body {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  background: $white;
  flex-wrap: wrap;
  padding-bottom: 100px;

  .body_img {
    width: auto;
    
    img {
      width: 1000px;
      height: 720px;
      margin-top: 50px;
      margin-left: 10px;
      border-radius: 10px;
      z-index: 1;
  
      @media screen and (max-width: 1100px) {
        width: 700px;
        height: 600px;
        margin-left: 0;
      }
      
      @media screen and (max-width: 850px) {
        width: 375px;
        height: 275px;
        margin-left: 0;
      }
      
      @media screen and (max-width: 650px) {
        width: 425px;
        height: 250px;
      }
      
      @media screen and (max-width: 450px) {
        width: 275px;
        height: 175px;      }
    }
  }

  .app_about_container {
    width: 600px;
    height: 720px;
    z-index: 2;
    background: $black;
    border-radius: 5px;
    margin-left: 20px;
    margin-top: 45px;

    .app_about_content {
      display: flex;
      flex-direction: column;
      padding: 65px;
      
      .content_tag {
        width: 100px;
        height: 35px;
        margin-bottom: 20px;
        background: $blue;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;

        h3 {
          font-family: $font-primary;
          color: $white;
          font-weight: 500;
        }
      }

      .content_title {
        font-family: $font-primary;
        color: $white;
        font-weight: 400;
        margin-bottom: 50px;
        line-height: 30px;

        @media screen and (max-width: 1600px) {
          // width: 80%;
          // margin-left: 32%;
        }
        
        @media screen and (max-width: 650px) {
          width: 300px;
        }
      }

      .tag_header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 50px;
        font-family: $font-primary;
        line-height: 30px;

        img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin-bottom: 10px;
          }

        h2 {
          color: $aqua;
        }

        h4 {
            color: $blue;
            font-weight: 400;
        }

        @media screen and (max-width: 1600px) {
          align-items: center;
        }
        
        @media screen and (max-width: 650px) {
          width: 300px;
        }
      }

      .body_content {
        font-family: $font-primary;
        color: $bright-white;
        font-weight: 300;

        @media screen and (max-width: 1600px) {
          font-size: 24px;
        }
        
        @media screen and (max-width: 650px) {
          width: 300px;
          font-size: 20px;
        }
      }

      .schedule_button {
        width: 200px;
        height: 40px;
        background: $aqua;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        border-radius: 5px;
        margin-top: 50px;

        a {
          text-decoration: none;
          color: $black;
          font-family: $font-primary;
          font-weight: 400;
        }
        
        a:hover {
          transition: 0.3s;
          color: $white;
        }

        svg {
          margin-left: 5px;
          color: $black;
          vertical-align: middle;
        }
        
        svg:hover {
          transition: 0.3s;
          color: $white;
        }

        @media screen and (max-width: 650px) {
          margin-bottom: 30px;
        }
      }

      @media screen and (max-width: 1600px) {
        justify-content: center;
        align-items: center;

      }

      @media screen and (max-width: 650px) {
        align-items: center;
        padding: 0;
        padding-top: 100px;
        padding-left: 0;
      }
    }
    
    @media screen and (max-width: 1600px) {
      position: relative;
      width: 100%;
      height: auto;
    }
    
    @media screen and (max-width: 650px) {
      display: flex;
      flex-direction: column;
      justify-content: center; 
      margin-left: 0;
      width: 100%;
      line-height: 20px;
      height: auto;
    }
  }
}