@import '../../index.scss';

.app_services {
    width: auto;
    height: 100%;
    background: $black;
    display: flex;
    flex-direction: column;

    .app_services_banner {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;  
        font-family: $font-primary;
        padding-top: 300px;

        h1 {
            color: $aqua;
            font-weight: normal;
            text-transform: capitalize;
            font-size: 42px;
            line-height: 80px;
            margin-top: 250px;

            @media screen and (max-width: 650px) {
              font-size: 4px;
              line-height: 50px;
              margin-top: 0;
            }
        }

        .services_tag {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100px;
            height: 40px;
            background: $blue;
            border-radius: 5px;
            position: absolute;
            margin-bottom: 250px;

            h3 {
                font-weight: 400;
                color: $white;
            }

        } 
        
        .services_title {
            text-align: center;
            width: auto;

            @media screen and (max-width: 850px) {
              margin-top: 600px;
            }
            
            @media screen and (max-width: 650px) {
              margin-top: 100px;
            }

            @media screen and (max-width: 1400px) {
              font-size: 36px;
            }
        }

        img {
          width: 200px;
          height: 200px;
          margin-top: 50px;
        }
      
      @media screen and (max-width: 650px) {
        width: auto;
        justify-content: space-between;
        padding-top: 150px;
      }


    }

    .app_services_container { 
        width: 100%;
        margin-top: 20%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
        position: relative;

        .service_item {
            width: 500px;
            height: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            background: transparent;
            border-radius: 5px;
            font-family: $font-primary;
            text-align: center;
            padding: 20px;
            margin-top: 30px;
            
            h3 {
                font-weight: normal;
                letter-spacing: 1px;
                font-weight: bold;
                font-size: 20px;
                color: $bright-white;
                margin-top: 10px;
            }
            
            p {
                margin-top: 10px;
                font-weight: normal;
                letter-spacing: 1px;
                font-weight: normal;
                font-size: 18px;
                color: $white;
            }
            .service_icon {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background: $black;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .schedule_button {
              width: 200px;
              height: 40px;
              background: $blue;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: row;
              border-radius: 5px;
              margin-top: 50px;
      
              a {
                text-decoration: none;
                color: $white;
                font-family: $font-primary;
                font-weight: 400;
                text-align: center;
              }
              
              a:hover {
                transition: 1s;
                color: $grey;
              }
      
              svg {
                margin-left: 10px;
                color: $white;
                padding-top: 5px;
      
                :hover {
                  transition: 1s; 
                  color: $grey;
                }
              }
            }
  
            @media screen and (max-width: 850px) {
              margin-right: 0;
            }

            @media screen and (max-width: 750px) {
              width: 500px;
              // margin-left: 120px;
              margin-right: 0;
            }
            
            @media screen and (max-width: 650px) {
              width: 500px;
              margin-right: 0;
            }
            
            @media screen and (max-width: 550px) {
              width: 500px;
              margin-left: 0;
            }

            @media screen and (max-width: 450px) {
              width: 400px;
              margin-left: 0;
            }
        }

      @media screen and (max-width: 1400px) {

      }
      
      @media screen and (max-width: 850px) {
        // margin-top: 950px;
        justify-content: center;
        align-items: center;
        margin-left: 0;
      }
      
      @media screen and (max-width: 550px) {
        margin-right: 0;
      }
    }

  // @media screen and (max-width: 1000px) {
  //   height: 140vh;
  // }
  
  // @media screen and (max-width: 850px) {
  //   height: 160vh;
  // }
}