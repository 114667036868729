@import '../../index.scss';

.app_header {
  width: 100%;
  height: 100%;
  background: $white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1;
  font-family: $font-primary;
  position: relative;
  background-position: right center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../../assets/bgart.png');
  z-index: 0;
  flex-wrap: wrap;

  @media screen and (min-width: 1200px) {
    height: 100vh;
  }

  @media screen and (max-width: 1400px) {
    justify-content: center;
  }
}

.app_header_banner {
  display: flex;
  width: auto;
  flex-direction: column;
  margin-right: 200px;

  .app_header_banner_body {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;

    .banner-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: auto;

      @media screen and (max-width: 600px) {
        width: 375px;
      }
    }

    .top {
      color: $black;
      font-size: 72px;

      @media screen and (max-width: 600px) {
        font-size: 48px;
      }

      @media screen and (max-width: 850px) {
        font-size: 36px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .bottom {
      color: $blue;
      font-size: 72px;
      padding-left: 30px;

      @media screen and (max-width: 600px) {
        font-size: 48px;
        padding-left: 0;
      }

      @media screen and (max-width: 850px) {
        font-size: 36px;
      }

      @media screen and (max-width: 850px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    
    .desc {
      position: relative;
      padding-left: 30px;
      margin-top: 20px;
      font-size: 20px;
      color: $dark-grey;

      @media screen and (max-width: 600px) {
        padding-left: 0;
      }
    }

    @media screen and (min-width: 1300px) {
      flex-direction: row;
      justify-content: space-between;
    }

    @media screen and (max-width: 1300px) {
      padding-left: 0;
    }
  }

  .app_header_banner_invite {
    width: 500px;
    height: auto;
    padding-top: 150px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      font-size: 36px;
      font-weight: normal;
    }

    .contact-btn {
      width: 100px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $aqua;
      margin-top: 30px;
      border-radius: 5px;

      a {
        text-decoration: none;
        color: $black;
      }

      a:hover {
        color: $white;
        transition: 0.3s;
      }
    }

    @media screen and (max-width: 1500px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }

  @media screen and (min-width: 1300px) {
    width: 100%;
  }
  
  @media screen and (max-width: 1500px) {
    margin-right: 0;
    height: 700px;
    margin: auto;
    background: rgba(255, 255, 255, 0.50);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 20px;
    align-items: center;
    padding-top: 50px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.app_header_img {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  margin-top: 50px;

  img {
    border-radius: 50%;
    z-index: 1;
    width: 600px;

    @media screen and (max-width: 1300px) {
      width: 300px;
    }
  }

  @media screen and (max-width: 1300px) {
    justify-content: center;
    align-items: center;
  }

}

.app_header_comment {
  width: 300px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  padding-left: 30px;
  position: absolute;
  z-index: 3;
  margin-left: 150px;
  margin-top: 400px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.50);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  padding-bottom: 10px;
  line-height: 25px;

  h1 {
    font-size: 20px;
    font-weight: normal;
  }

  h2 {
    font-size: 18px;
    color: $black;
  }

  .percent {
    display: flex;
    flex-direction: row;

    .percent_bubble {
      padding-right: 5px;
    }
  }

  @media screen and (max-width: 1400px) {
    justify-content: center;
    margin-right: 0;
    // margin-left: 55px;
    margin-top: 175px;
  }
}


//   // @media screen and (max-width: 1100px) {
//   //   height: 120vh;
//   // }
  
//   @media screen and (max-width: 850px) {
//     height: 140vh;
//   }
  
//   @media screen and (max-width: 650px) {
//     height: 100vh;
//   }
  
//   @media screen and (max-width: 450px) {
//     height: 100vh;
//   }

//   .app_header_img {
//     width: 50%;
//     height: 30%;
//     display: flex;
//     flex-direction: column;
//     margin-top: 85px;
//     justify-content: center;
//     position: relative;
//     align-items: flex-end;
//     margin-left: 30%;

//     img {
//       width: 700px;
//       height: 80%;
//       z-index: 1;

//       @media screen and (min-width: 2000px) {
//         width: 650px;
//         height: 900px;
//         margin-top: 10px;
//         margin-left: 200px;
//       }

//       @media screen and (max-width: 1150px) {
//         width: 600px;
//         height: 900px;
//         margin-top: 120px;
//         margin-left: 70px;
//         margin-right: 0;
//       }
      
//       @media screen and (max-width: 650px) {
//         width: 400px;
//         height: 700px;
//         margin-top: 700px;
//         margin-left: 600px;
//       }
      
//       @media screen and (max-width: 450px) {
//         width: 400px;
//         height: 700px;
//         margin-top: 700px;
//         margin-left: 600px;
//       }
//     }

//     h2 { 
//       font-family: $font-cursive;
//       font-weight: 300;
//       font-size: 50px;
//       color: $cranberry;

//       @media screen and (max-width: 650px) {
//         font-size: 28px;
//         margin-right: 50px;
//         margin-top: 10%;
//       }
      
//       @media screen and (max-width: 450px) {
//         font-size: 28px;
//         margin-right: 50px;
//         margin-top: 10%;
//       }
//     }

//     .app_header_bg {
//       position: absolute;
//       width: 70%;
//       height: 78%;
//       z-index: 0;
//       opacity: 40%;
//       display: flex;
//       justify-content: center;
//       align-items: center;

//       h1 {
//         font-size: 1300px;
//         font-family: $font-secondary;
//         color: $beige;
        
//         @media screen and (max-width: 850px) {
//           font-size: 1000px;
//         }
        
//         @media screen and (max-width: 650px) {
//           font-size: 550px;
//         }
        
//         @media screen and (max-width: 450px) {
//           font-size: 550px;
//         }
//       }


//       @media screen and (min-width: 2000px) {
//         width: 600px;
//         height: 800px;
//         margin-top: 0;
//       }
      
//       @media screen and (max-width: 1150px) {
//         margin-top: 50px;
//         margin-right: 90px;
//         width: 500px;
//         height: 700px;
//       }
      
//       @media screen and (max-width: 850px) {
//         margin-right: 0;
//       }
      
//       @media screen and (max-width: 650px) {
//         margin-top: 520px;
//         margin-right: 50px;
//         width: 250px;
//         height: 400px;
//       }
      
//       @media screen and (max-width: 450px) {
//         margin-top: 520px;
//         margin-right: 50px;
//         width: 250px;
//         height: 400px;
//       }
//     }

//     .app_header_msg {
//         background: rgba(255, 255, 255, 0.50);
//         backdrop-filter: blur(4px);
//         -webkit-backdrop-filter: blur(4px);
//         z-index: 2;
//         position: absolute;
//         margin-bottom: 70%;
//         margin-right: -100px;
//         width: 300px;
//         height: 100px;
//         display: flex;
//         justify-content: center;
//         flex-direction: column;
//         border-radius: 10px;

//         @media screen and (max-width: 650px) {
//           font-size: 14px;
//           margin-right: 0;
//         }
        
//         @media screen and (max-width: 450px) {
//           font-size: 14px;
//           margin-right: 0;
//         }

//       .subtitle-text {
//         font-family: $font-primary;
//         font-weight: 200;
//         font-size: 22px;
//         padding: 30px;
//         color: $black;

//         @media screen and (max-width: 650px) {
//           font-size: 14px;

//         }
        
//         @media screen and (max-width: 450px) {
//           font-size: 14px;

//         }
//       }

//       @media screen and (min-width: 2000px) {
//         margin-bottom: 35%;
//         margin-left: 30%;
//       }

//       @media screen and (max-width: 1100px) {
//         width: 250px;
//         height: 75px;
//         margin-right: -60px;
//         margin-top: 0;
//         margin-bottom: 400px;
//       }
      
//       @media screen and (max-width: 650px) {
//         width: 150px;
//         height: 50px;
//         margin-left: 0;
//         margin-top: 800px;
//         margin-right: 0;
//       }
      
//       @media screen and (max-width: 450px) {
//         width: 150px;
//         height: 50px;
//         margin-left: 0;
//         margin-top: 800px;
//         margin-right: 0;
//       }
//     }

//     @media screen and (max-width: 850px) {
//       margin-top: 700px;
//       margin-right: 250px;
//     }

//     @media screen and (min-width: 650px) and (max-width: 800px) {
//       margin-right: 100px;
//     }
    
//     @media screen and (max-width: 650px) {
//       margin-top: 70px;
//       margin-left: 0;
//       margin-right: 40px;
//     }
    
//     @media screen and (max-width: 550px) {
//       margin-top: 70px;
//       margin-left: 70px;
//       margin-right: 40px;
//     }
    
//     @media screen and (max-width: 450px) {
//       margin-top: 70px;
//       margin-left: 150px;
//       margin-right: 40px;
//     }
//   }

//   .app_header_comment {
//     width: 300px;
//     height: 100px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     background: rgba(255, 255, 255, 0.2);
//     padding-left: 30px;
//     position: absolute;
//     z-index: 3;
//     margin-right: 50%;
//     margin-top: 30%;
//     border-radius: 10px;
//     background: rgba(255, 255, 255, 0.50);
//     backdrop-filter: blur(4px);
//     -webkit-backdrop-filter: blur(4px);

//     h1 {
//         font-family: $font-primary;
//         font-size: 18px;
//         padding-bottom: 5px;

//         @media screen and (max-width: 650px) {
//           padding-bottom: 0;
//           font-size: 12px;
//           width: 100px;
//         }
        
//         @media screen and (max-width: 450px) {
//           padding-bottom: 0;
//           font-size: 12px;
//           width: 100px;
//         }
//     }

//     h2 {
//         font-family: $font-primary;
//         font-size: 14px;
//         padding-bottom: 5px;
//         color: $off-white;

//         @media screen and (max-width: 650px) {
//           margin-bottom: 10px;
//           font-size: 10px;
//           height: 10px;
//           margin-top: 5px;
//         }
        
//         @media screen and (max-width: 450px) {
//           margin-bottom: 10px;
//           font-size: 10px;
//           height: 10px;
//           margin-top: 5px;
//         }
//     }

//     .percent {
//         display: flex;
//         flex-direction: row;
//         font-family: $font-primary;
//         font-size: 14px;

//         p {
//             color: black;
//             margin-left: 10px;

//             @media screen and (max-width: 650px) {
//               font-size: 12px;
//             }
            
//             @media screen and (max-width: 450px) {
//               font-size: 12px;
//             }
//         }

//         .percent_bubble {
//             background: $black;
//             border-radius: 45%;
            
//             p {
//                 padding-right: 10px;
//                 color: $white;

//                 @media screen and (max-width: 650px) {
//                   font-size: 12px;
//                   display: flex;
//                   justify-content: center;
//                   align-items: center;
//                   padding-top: 2.5px;
//                 }
                
//                 @media screen and (max-width: 450px) {
//                   font-size: 12px;
//                   display: flex;
//                   justify-content: center;
//                   align-items: center;
//                   padding-top: 2.5px;
//                 }
//             }

//             @media screen and (max-width: 650px) {
//               width: 35px;
//               height: 20px;
//             }
            
//             @media screen and (max-width: 450px) {
//               width: 35px;
//               height: 20px;
//             }
//         }

//         @media screen and (max-width: 650px) {
//               width: 35px;
//               height: 20px;
//         }
        
//         @media screen and (max-width: 450px) {
//               width: 35px;
//               height: 20px;
//         }
//     }

//     img {
//         position: absolute;
//         margin-left: 180px;
//         margin-top: 20px;
//         width: 100px;
//         height: 100px;

//         @media screen and (max-width: 650px) {
//           width: 70px;
//           height: 70px;
//           margin-left: 100px;
//       }
        
//       @media screen and (max-width: 450px) {
//           width: 70px;
//           height: 70px;
//           margin-left: 100px;
//       }
//     }

//     @media screen and (max-width: 650px) {
//       margin-top: 800px;
//       margin-right: 190px;
//       width: 180px;
//       height: 80px;
//       padding-left: 10px;
//     }
    
//     @media screen and (max-width: 450px) {
//       margin-top: 800px;
//       margin-right: 190px;
//       width: 180px;
//       height: 80px;
//       padding-left: 10px;
//     }
//   }

//   .app_header_banner {
//     position: absolute;
//     display: flex;
//     width: 950px;
//     height: 150px;
//     margin-top: 15%;
//     margin-right: 40%;
//     z-index: 2;

//     .app_header_banner_body {
//         display: flex;
//         flex-direction: row;
//         flex-wrap: wrap;
        
//         .top {
//             color: $off-white;
//             padding-right: 20px;

//             @media screen and (max-width: 1600px) {
//               font-size: 106px;
//               margin-left: 160px;
//             }
            
//             @media screen and (max-width: 1100px) {
//               font-size: 96px;
//               margin-left: 25%;
//             }
            
//             @media screen and (max-width: 850px) {
//               font-size: 96px;
//               margin-left: 32%;
//             }
            
//             @media screen and (max-width: 650px) {
//               font-size: 50px;
//               padding-right: 0;
//               margin-left: 70px;
//               height: 60px;
//             }
            
//             @media screen and (max-width: 450px) {
//               font-size: 50px;
//               padding-right: 0;
//               margin-left: 70px;
//               height: 60px;
//             }
//         }
    
//         .middle {
//             color: $gold;

//             @media screen and (max-width: 1600px) {
//               font-size: 106px;
//               margin-left: 0;
//             }
            
//             @media screen and (max-width: 1100px) {
//               font-size: 96px;
//               margin-left: 20px;
//             }
            
//             @media screen and (max-width: 850px) {
//               font-size: 96px;
//               margin-left: 32%;
//             }
            
//             @media screen and (max-width: 650px) {
//               font-size: 50px;
//               padding-right: 0;
//               margin-left: 20px;
//               height: 60px;
//             }
            
//             @media screen and (max-width: 450px) {
//               font-size: 50px;
//               padding-right: 0;
//               margin-left: 20px;
//               height: 60px;
//             }
//         }

//         .bottom {
//             margin-left: 10px;
//             color: $orange;

//             @media screen and (max-width: 1600px) {
//               font-size: 106px;
//               margin-left: 165px;
//             }
            
//             @media screen and (max-width: 1100px) {
//               font-size: 96px;
//               margin-left: 26%;
//             }
            
//             @media screen and (max-width: 850px) {
//               font-size: 96px;
//               margin-left: 32%;
//             }
            
//             @media screen and (max-width: 650px) {
//               font-size: 50px;
//               margin-left: 10px;
//               height: 60px;
//               margin-left: 70px;
//             }
            
//             @media screen and (max-width: 450px) {
//               font-size: 50px;
//               margin-left: 10px;
//               height: 60px;
//               margin-left: 70px;
//             }
//         }
        
//         h1 {
//           font-family: $font-primary;
//           font-weight: 400;
//           color: $white;
//           height: 120px;
//           font-size: 116px;
//         }

//         p {
//             font-family: $font-primary;
//             font-size: 20px;
//             margin-top: 70px;
//             margin-left: 15px;
//             color: $dark-grey;
//             opacity: 60%;

//             @media screen and (max-width: 1600px) {
//               margin-left: 20px;
//               margin-right: 0;
//               color: $grey;
//             }
            
//             @media screen and (max-width: 1500px) {
//               margin-left: 160px;
//               margin-right: 0;
//               margin-top: 100px;
//               color: $gold;
//             }
            
//             @media screen and (max-width: 1100px) {
//               margin-left: 26%;
//               margin-right: 0;
//               margin-top: 100px;
//               color: $gold;
//             }
            
//             @media screen and (max-width: 850px) {
//               margin-left: 300px;
//               margin-right: 0;
//               margin-top: 40px;
//             }
            
//             @media screen and (max-width: 650px) {
//               margin-top: 20px;
//               margin-left: 70px;
//               margin-right: 50px;
//               color: $grey;
//               width: 400px;
//             }
//         }

//         @media screen and (max-width: 650px) {
//           font-size: 70px;
//           justify-content: flex-start;
//         }
        
//         @media screen and (max-width: 450px) {
//           font-size: 70px;
//           justify-content: flex-start;
//         }
//     }
    

//     // @media screen and (max-width: 2000px) {
//     //   font-size: 16px;
//     //   width: 500px;
//     //   height: 30px;
//     //   margin-right: 50%;
//     // }
    
//     @media screen and (max-width: 650px) {
//       font-size: 10px;
//       width: 300px;
//       height: 30px;
//       margin-right: 200px;
//     }
    
//     @media screen and (max-width: 450px) {
//       font-size: 10px;
//       width: 300px;
//       height: 30px;
//       margin-right: 200px;
//     }
//   }

//   .app_header_banner_invite {
//     position: absolute;
//     display: flex;
//     width: 600px;
//     height: 400px;
//     align-items: center;
//     margin-top: 50%;
//     margin-right: 70%;
//     flex-direction: column;

//     .subtitle-text {
//       font-family: $font-primary;
//       font-weight: 300;
//       font-size: 28px;
//       padding: 30px;
//       color: $off-white;

//       @media screen and (max-width: 1150px) {
//         font-size: 20px;
//       }
//     }

//     .contact-btn {
//       width: 150px;
//       height: 45px;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       background: $off-white;
//       border-radius: 5px;

//       a {
//         text-decoration: none;
//         font-size: 18px;
//         font-family: $font-primary;
//         color: black;
//         font-weight: 300;

//         @media screen and (max-width: 650px) {
//           font-size: 14px;
//         }
        
//         @media screen and (max-width: 450px) {
//           font-size: 14px;
//         }

//         // @media screen and (max-width: 1150px) {
//         //   font-size: 18px;
//         // }
//       }
    
//     :hover {
//       color: $white;
//       transition: 0.5s;
//     }

//     @media screen and (max-width: 650px) {
//       width: 120px;
//       height: 30px;
//     }
    
//     @media screen and (max-width: 450px) {
//       width: 120px;
//       height: 30px;
//     }

//     // @media screen and (max-width: 1150px) {
//     //   width: 175px;
//     //   height: 50px;
//     // }
//   }

//   @media screen and (min-width: 2000px) {
//     margin-top: 22%;
//     margin-left: 60%;
//   }

//   @media screen and (max-width: 1500px) {
//     justify-content: flex-start;
//     margin-top: 60%;
//     margin-right: 65%;
//   }
  
//   @media screen and (max-width: 1100px) {
//     justify-content: flex-start;
//     margin-top: 80%;
//     margin-right: 60%;
//   }

//   @media screen and (max-width: 850px) {
//     width: 400px;
//     height: 300px;
//     margin-left: 0;
//     margin-right: 0;
//     margin-top: 1400px;
//     z-index: 2;
//   }
  
//   @media screen and (max-width: 650px) {
//     width: 400px;
//     height: 300px;
//     margin-left: 0;
//     margin-right: 0;
//     margin-top: 950px;
//     z-index: 2;
//   }
  
//   @media screen and (max-width: 450px) {
//     width: 300px;
//     height: 200px;
//     margin-left: 0;
//     margin-right: 0;
//     z-index: 2;
//   }

//   }

//   @media screen and (max-width: 650px) {
//     height: 120vh;
//   }
  
//   @media screen and (max-width: 450px) {
//     height: 120vh;
//   }
// }